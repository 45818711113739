import { action, observable } from 'mobx';

export type SentimentLabels = 'positive' | 'negative' | 'neutral';

export type LabelType = 'autoTagged' | 'gptPredicted' | 'normal';
export class CustomSentimentDocumentLabelStore {
    @observable public category: SentimentLabels;

    public offset: number;

    public length: number;

    constructor(labelData: Partial<CustomSentimentDocumentLabelStore>) {
        this.category = labelData.category;
        this.offset = labelData.offset;
        this.length = labelData.length;
    }

    @action
    public setCategory(category: SentimentLabels) {
        this.category = category;
    }
}
