import { action, computed, observable } from 'mobx';
import { AppData, AppStore } from 'src/core/stores/app/AppStore';
import { CustomSkillDeploymentStore } from 'src/core/stores/CustomSkillDeploymentStore';
import { ResourceAssignmentStore } from 'src/core/stores/ResourceAssignmentStore';
import { EntityStore } from 'src/core/types/EntityStore';
import { truncateValue } from 'src/core/utils/number';
import { AutoLabelingSentimentPrediction } from 'src/customSentiment/core/services/gpt/customSentimentAutoLabelingSwagger';
import { AutoTaggingJobStore } from 'src/customSentiment/core/stores/CustomSentimentAutoTaggingJobStore';
import { CustomSentimentDocumentLabelStore } from 'src/customSentiment/core/stores/CustomSentimentDocumentLabelStore';
import {
    CustomSentimentDocumentMetadata,
    CustomSentimentDocumentStore
} from 'src/customSentiment/core/stores/CustomSentimentDocumentStore';
import { CustomSentimentGptPredictionsStore } from 'src/customSentiment/core/stores/CustomSentimentGptPredictionStore';
import { CustomSentimentTrainedModelStore } from 'src/customSentiment/core/stores/CustomSentimentTrainedModelStore';
import { createResourceStoreHouse } from 'src/luis/core/stores/resourceStoreHouse';
import { ModelEvaluationStore } from 'src/modules/modelDetails/stores/ModelEvaluationStore';
import { EvaluationJobStore } from 'src/modules/modelEvaluation/EvaluationJobInfoStore';
import { ModelGuidanceStore } from 'src/modules/modelGuidance/stores/ModelGuidanceStore';
import { TrainingJobStore } from 'src/modules/modelTraining/stores/TrainingJobInfoStore';
import { TrainingServiceStatus } from 'src/modules/modelTraining/types/trainingTypes';

export type CustomSentimentProjectKind = 'CustomTextSentiment';

export type CustomSentimentProjectStoreMetadata = AppData & {
    description: string;
    containerName: string;
    multiLingual: boolean;
    nextBatchExists: boolean;
    projectKind: CustomSentimentProjectKind;
    documents: CustomSentimentDocumentMetadata[];
};

export type ProjectDomain = 'documents';

export class CustomSentimentProjectStore extends AppStore {
    public containerName: string;

    public projectKind: CustomSentimentProjectKind;

    public readonly deploymentsStoreHouse = createResourceStoreHouse<CustomSkillDeploymentStore>();

    public readonly trainingJobStoreHouse = createResourceStoreHouse<TrainingJobStore>();

    public readonly trainedModels = createResourceStoreHouse<CustomSentimentTrainedModelStore>();

    @observable public autoTaggingQuota: { totalQuota: number; consumedQuota: number };

    @observable public savingState: { saved: boolean; latestChangeDomain?: ProjectDomain };

    @observable public multiLingual: boolean;

    @observable public nextBatchExists: boolean;

    @observable public evaluationJobStore: EvaluationJobStore;

    @observable.shallow public documents: CustomSentimentDocumentMetadata[];

    @observable.shallow public jsonDocuments: CustomSentimentDocumentMetadata[];

    @observable.shallow public currentDocumentStore: CustomSentimentDocumentStore;

    @observable public modelsEvaluationMap = new Map<string, ModelEvaluationStore>();

    @observable public modelsGuidanceMap = new Map<string, ModelGuidanceStore>();

    public resourceAssignmentsStoreHouse = createResourceStoreHouse<ResourceAssignmentStore>();

    public readonly gptPredictionsStore = new CustomSentimentGptPredictionsStore();

    public readonly gptJobsStoreHouse = createResourceStoreHouse<AutoTaggingJobStore>();

    constructor(metadata: Partial<CustomSentimentProjectStoreMetadata>) {
        super(metadata.name, metadata.projectKind, metadata);

        this.savingState = { saved: true };
        this.projectKind = metadata.projectKind;
        this.name = metadata.name;
        this.containerName = metadata.containerName;
        this.multiLingual = metadata.multiLingual;
        this.nextBatchExists = metadata.nextBatchExists;
        this.description = metadata.description;
        this.documents = metadata.documents || [];
        this.jsonDocuments = metadata.documents || [];
    }

    @computed
    public get labelsCount(): Map<string, number> {
        return this.documents.reduce((map, document) => {
            if (document.name === this.currentDocumentStore?.name) {
                this.currentDocumentStore.sentimentLabel.forEach(label => {
                    const category = label.category.charAt(0).toUpperCase() + label.category.slice(1);
                    map.set(category, (map.get(category) ?? 0) + 1);
                });
            } else {
                document.sentimentLabel.forEach(label => {
                    const category = label.category.charAt(0).toUpperCase() + label.category.slice(1);
                    map.set(category, (map.get(category) ?? 0) + 1);
                });
            }

            return map;
        }, new Map<string, number>());
    }

    @computed
    public get entities(): EntityStore[] {
        const entities = [...this.labelsCount.keys()].map(
            label => new EntityStore({ name: label.charAt(0).toUpperCase() + label.slice(1) })
        );
        return entities.reverse();
    }

    @computed
    public get isDirty(): boolean {
        return !this.savingState.saved;
    }

    /**
     * Gets the percentage of documents in the training set defined by the user
     */
    @computed
    public get trainingSetPercentage(): number {
        return truncateValue((this.documents.filter(d => d.documentDataset === 'Train').length * 100) / this.labeledDocumentsCount, 2);
    }

    /**
     * Gets the percentage of documents in the testing set defined by the user
     */
    @computed
    public get testingSetPercentage(): number {
        return truncateValue((this.documents.filter(d => d.documentDataset === 'Test').length * 100) / this.labeledDocumentsCount, 2);
    }

    @computed
    public get labeledDocumentsCount(): number {
        return this.documents.filter(d => !!d.sentimentLabel.length).length;
    }

    @computed
    public get isControlDatasetUserDefined(): boolean {
        return this.documents.some(d => d.documentDataset === 'Test');
    }

    /**
     * Gets the model that is currently being trained.
     */
    @computed
    public get currentTrainingJob(): TrainingJobStore {
        return this.trainingJobStoreHouse.items.find(t => t.status === 'inProgress');
    }

    /**
     * Gets the successfully trained model.
     */
    @computed
    public get successfullyTrainedModel(): CustomSentimentTrainedModelStore[] {
        return this.trainedModels.items;
    }

    /**
     * Gets whether a model is being trained or not.
     */
    @computed
    public get trainingServiceStatus(): TrainingServiceStatus {
        if (this.currentTrainingJob) {
            return 'inProgress';
        }

        return this.trainingJobStoreHouse.items.find(t => t.status === 'notStarted' || t.status === 'cancelling') ? 'queued' : 'ready';
    }

    @action
    public updateProject(metadata: Partial<CustomSentimentProjectStoreMetadata>, documents: CustomSentimentDocumentMetadata[]) {
        this.description = metadata.description;
        this.multiLingual = metadata.multiLingual;
        this.setProjectDocuments(documents);
    }

    @action
    public setProjectDocuments(documents: CustomSentimentDocumentMetadata[]) {
        this.documents = documents;
    }

    @action
    public setProjectJsonDocuments(documents: CustomSentimentDocumentMetadata[]) {
        this.jsonDocuments = documents;
    }

    @action
    public setIsDirty(isDirty: boolean, latestChangeDomain?: ProjectDomain) {
        this.savingState = { saved: !isDirty, latestChangeDomain };
    }

    @action
    public setCurrentDocumentStore(documentStore: CustomSentimentDocumentStore) {
        this.currentDocumentStore = documentStore;
    }

    @action
    public setMultiLingual(multiLingual: boolean): void {
        this.multiLingual = multiLingual;
    }

    /**
     * Updates the container marker to indicate the next blob to be fetched from the container
     */
    @action
    public setNextBatchExists(value: boolean): void {
        this.nextBatchExists = value;
    }

    @action
    public addModelEvaluationMapItem(k: string, val: ModelEvaluationStore): void {
        this.modelsEvaluationMap.set(k, val);
    }

    @action
    public addModelGuidanceMapItem(k: string, val: ModelGuidanceStore): void {
        this.modelsGuidanceMap.set(k, val);
    }

    @action
    public setAutoTaggingQuota(quota: CustomSentimentProjectStore['autoTaggingQuota']) {
        this.autoTaggingQuota = quota;
    }

    @action
    public resetDocumentSentiments(documentId: string) {
        this.documents = this.documents.map(d => (d.name === documentId ? { ...d, sentimentLabel: [], documentDataset: null } : d));
        this.currentDocumentStore?.setSentimentLabel([]);
        this.currentDocumentStore?.setDocumentDataSet(null);
    }

    @action
    public addPredictedLabelToDocument(documentId: string, sentiments: CustomSentimentDocumentLabelStore[]) {
        const document = this.documents.find(d => d.name === documentId);
        if (document) {
            document.documentDataset = 'Train';
            document.sentimentLabel = sentiments;
        }
    }

    @action
    public addModifiedLabelToDocument(documentId: string, sentiments: AutoLabelingSentimentPrediction) {
        const document = this.documents.find(d => d.name === documentId);
        if (document) {
            document.documentDataset = 'Train';
            document.sentimentLabel = [
                ...document.sentimentLabel,
                ...sentiments.map(
                    s =>
                        new CustomSentimentDocumentLabelStore({
                            category: s.updatedCategory,
                            offset: s.updatedOffset,
                            length: s.updatedLength
                        })
                )
            ];
        }
    }

    @action
    public setEvaluationJobStore(val: EvaluationJobStore): void {
        this.evaluationJobStore = val;
    }

    /**
     * Gets the auto-tagging job that is currently being running.
     */
    // @computed
    // public get currentRunningAutoTaggingJob(): AutoTaggingJobStore {
    //     return this.autoTaggingJobStoreHouse.items.find(j => j.status === 'inProgress');
    // }
}
