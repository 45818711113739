import {
    CustomSentimentExportedProjectDocumentSwagger,
    CustomSentimentExportedProjectSwagger,
    CustomSentimentProjectSwagger
} from 'src/customSentiment/core/services/projects/CustomSentimentProjectSwaggers';
import { CustomSentimentDocumentLabelStore } from 'src/customSentiment/core/stores/CustomSentimentDocumentLabelStore';
import {
    CustomSentimentDocumentMetadata,
    CustomSentimentDocumentStore,
    emptyCustomSentimentDocumentMetadata
} from 'src/customSentiment/core/stores/CustomSentimentDocumentStore';
import { CustomSentimentProjectStore } from 'src/customSentiment/core/stores/CustomSentimentProjectStore';

export const projectSwaggerToProjectStore = (
    projectSwagger: CustomSentimentProjectSwagger,
    documents?: CustomSentimentDocumentMetadata[]
) =>
    new CustomSentimentProjectStore({
        documents,
        name: projectSwagger.projectName,
        culture: projectSwagger.language,
        projectKind: projectSwagger.projectKind,
        description: projectSwagger.description,
        containerName: projectSwagger.storageInputContainerName,
        multiLingual: projectSwagger.multiLingual || projectSwagger.multilingual,
        createdDate: projectSwagger.createdDateTime && new Date(projectSwagger.createdDateTime),
        modifiedDate: projectSwagger.lastModifiedDateTime && new Date(projectSwagger.lastModifiedDateTime)
    });

export const exportedProjectSwaggerToDocumentAndTagStores = (
    exportedProjectSwagger: CustomSentimentExportedProjectSwagger,
    documentsMetadata: CustomSentimentDocumentMetadata[]
): {
    documents: CustomSentimentDocumentMetadata[];
} => {
    const { documents: exportedDocuments = [] } = exportedProjectSwagger.assets;

    const unLabeledDocs = documentsMetadata.filter(d => !exportedDocuments.some(ed => d.name === ed.location));
    const allDocsWithoutLabels: CustomSentimentDocumentMetadata[] = exportedDocuments
        .map(document => ({
            ...emptyCustomSentimentDocumentMetadata,
            name: document.location,
            documentDataset: document.dataset || 'Train',
            language: document.language || exportedProjectSwagger.metadata.language,
            contentSize: documentsMetadata.find(doc => doc.name === document.location)?.contentSize ?? 0
        }))
        .concat(unLabeledDocs);

    const allDocsWithLabels = allDocsWithoutLabels.map(doc => {
        const documentInLabels = exportedDocuments.find(d => d.location === doc.name);
        if (documentInLabels) {
            return {
                ...doc,
                language: documentInLabels.language,
                sentimentLabel: documentInLabels.sentimentSpans.map(el => new CustomSentimentDocumentLabelStore(el))
            };
        }

        return doc;
    });

    return { documents: allDocsWithLabels };
};

export const projectStoreToExportedProjectSwagger = (projectStore: CustomSentimentProjectStore): CustomSentimentExportedProjectSwagger => {
    const documents: CustomSentimentExportedProjectDocumentSwagger[] = projectStore.documents
        .filter(d => d.sentimentLabel.length > 0)
        .map<CustomSentimentExportedProjectDocumentSwagger>(d => ({
            location: d.name,
            language: d.language,
            dataset: d.documentDataset,
            sentimentSpans: d.sentimentLabel
        }));

    return {
        metadata: {
            projectName: projectStore.name,
            language: projectStore.culture,
            projectKind: projectStore.projectKind,
            multiLingual: projectStore.multiLingual,
            multilingual: projectStore.multiLingual,
            description: projectStore.description,
            storageInputContainerName: projectStore.containerName
        },
        assets: {
            documents,
            projectKind: projectStore.projectKind
        },
        stringIndexType: 'Utf16CodeUnit'
    };
};

export const documentStoreToDocumentMetadata = (store: CustomSentimentDocumentStore): CustomSentimentDocumentMetadata => ({
    name: store.name,
    text: store.text,
    contentSize: store.contentSize,
    language: store.language,
    isInitialized: store.isInitialized,
    documentDataset: store.documentDataset,
    sentimentLabel: store.sentimentLabel
});

export const documentMetadataToDocumentStore = (metadata: CustomSentimentDocumentMetadata) =>
    new CustomSentimentDocumentStore({ ...metadata });

export const getCustomSentimentProjectImportDataFromFileContent = (fileContent: string) => {
    const projectSwagger: CustomSentimentExportedProjectSwagger = JSON.parse(fileContent);

    return {
        projectKind: projectSwagger.metadata?.projectKind,
        projectName: projectSwagger.metadata?.projectName
    };
};
