import { action, computed, observable } from 'mobx';
import { OmitFunctions } from 'src/core/stores/types';
import { CustomSentimentDocumentLabelStore } from 'src/customSentiment/core/stores/CustomSentimentDocumentLabelStore';
import { DatasetType } from 'src/modules/modelTraining/types/trainingTypes';

export type CustomSentimentDocumentStoreData = OmitFunctions<CustomSentimentDocumentStore>;

export type CustomSentimentDocumentMetadata = Pick<
    CustomSentimentDocumentStore,
    'name' | 'text' | 'contentSize' | 'isInitialized' | 'language' | 'documentDataset' | 'sentimentLabel'
>;

export const emptyCustomSentimentDocumentMetadata: CustomSentimentDocumentMetadata = {
    name: '',
    text: '',
    contentSize: 0,
    language: '',
    isInitialized: false,
    documentDataset: null,
    sentimentLabel: []
};

export class CustomSentimentDocumentStore {
    public name: string;

    public text: string;

    @observable public contentSize: number;

    @observable public language: string;

    @observable public isInitialized = false;

    @observable public documentDataset: DatasetType;

    @observable public sentimentLabel: CustomSentimentDocumentLabelStore[];

    @observable public isDirty: boolean;

    constructor(documentData: Partial<CustomSentimentDocumentStoreData>) {
        this.isDirty = false;
        this.name = documentData.name;
        this.language = documentData.language;
        this.documentDataset = documentData.documentDataset ?? null;
        this.sentimentLabel = documentData.sentimentLabel ?? [];
    }

    @computed
    public get isLabeledDocument() {
        return !!this.sentimentLabel;
    }

    @action
    public setSentimentLabel(label: CustomSentimentDocumentLabelStore[]) {
        this.sentimentLabel = label;
    }

    @action
    public addSentimentLabel(label: CustomSentimentDocumentLabelStore) {
        this.sentimentLabel = [...this.sentimentLabel, label];
    }

    @action
    public setDocumentDataSet(ds: DatasetType) {
        this.documentDataset = ds;
    }

    @action
    public setCulture(culture: string) {
        this.language = culture;
    }

    @action
    public setIsDirty(dirty: boolean) {
        this.isDirty = dirty;
    }

    /**
     * Updates the document tokenized text.
     */
    @action
    public initialize(text: string) {
        this.text = text;
        this.isInitialized = true;
    }
}
