import { DefaultPalette, ICalloutProps, IContextualMenuItem, IContextualMenuProps, IStyle, IconButton } from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import React from 'react';
import { defineMessages } from 'react-intl';
import { useIcons } from '../../../core/hooks/useIcons';
import { useLocalization } from '../../../core/hooks/useLocalization';
import { getScopedTheme } from '../../../core/themes/luisStylist';
import { studioLinks } from '../../../core/utils/routingUtils';

export enum LinksKind {
    SpeechStudio = 'SpeechStudio',
    LanguageStudio = 'LanguageStudio',
    CustomTranslator = 'CustomTranslator',
    VisionStudio = 'VisionStudio',
    DocumentIntelligence = 'DocumentIntelligence',
    ContentSafety = 'ContentSafety',
    AzureAIStudio = 'AzureAIStudio',
    AzureMachineLearning = 'AzureMachineLearning'
}

const itemsWithAboveLine: LinksKind[] = [LinksKind.AzureAIStudio, LinksKind.AzureMachineLearning];

const messages = defineMessages({
    SpeechStudio: {
        id: 'messages.SpeechStudio',
        defaultMessage: 'Speech Studio'
    },
    LanguageStudio: {
        id: 'messages.LanguageStudio',
        defaultMessage: 'Language Studio'
    },
    CustomTranslator: {
        id: 'messages.CustomTranslator',
        defaultMessage: 'Custom Translator'
    },
    VisionStudio: {
        id: 'messages.VisionStudio',
        defaultMessage: 'Vision Studio'
    },
    DocumentIntelligence: {
        id: 'messages.DocumentIntelligence',
        defaultMessage: 'Document Intelligence'
    },
    ContentSafety: {
        id: 'messages.ContentSafety',
        defaultMessage: 'Content Safety'
    },
    AzureAIStudio: {
        id: 'messages.AzureAIStudio',
        defaultMessage: 'Azure AI Studio'
    },
    AzureMachineLearning: {
        id: 'messages.AzureMachineLearning',
        defaultMessage: 'Azure Machine Learning'
    },
    LinkToOtherStudios: {
        id: 'messages.LinkToOtherStudios',
        defaultMessage: 'Link to other studios'
    },
    ComingSoon: {
        id: 'messages.ComingSoon',
        defaultMessage: ' (Coming soon)'
    }
});
export interface ILinkMenuProps {
    items: LinksKind[]; // If the props provide the list
}

export const LinksMenu = (props: ILinkMenuProps) => {
    const { localize } = useLocalization();
    const contentSafetyLaunchDate = '5/23/2023';
    useIcons([
        'SpeechStudio',
        'MachineLearningStudio',
        'OpenAiStudio',
        'ContentSafetyStudio',
        'TranslationStudio',
        'FormRecognizerStudio',
        'VisionStudio',
        'LanguageStudio'
    ]);
    const pstDate = new Date().toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles'
    });
    const isContentSafetyLaunched = contentSafetyLaunchDate < pstDate.toLocaleString();
    const linksMenuItems: IContextualMenuItem[] = [
        {
            key: LinksKind.SpeechStudio,
            text: localize(messages.SpeechStudio),
            href: studioLinks.SpeechStudio(),
            target: '_blank',
            iconProps: {
                iconName: 'SpeechStudio'
            }
        },
        {
            key: LinksKind.LanguageStudio,
            text: localize(messages.LanguageStudio),
            href: studioLinks.LanguageStudio(),
            target: '_blank',
            iconProps: {
                iconName: 'LanguageStudio'
            }
        },
        {
            key: LinksKind.CustomTranslator,
            text: localize(messages.CustomTranslator),
            href: studioLinks.CustomTranslator(),
            target: '_blank',
            iconProps: {
                iconName: 'TranslationStudio'
            }
        },
        {
            key: LinksKind.VisionStudio,
            name: localize(messages.VisionStudio),
            href: studioLinks.VisionStudio(),
            target: '_blank',
            iconProps: {
                iconName: 'VisionStudio'
            }
        },
        {
            key: LinksKind.DocumentIntelligence,
            name: localize(messages.DocumentIntelligence),
            href: studioLinks.DocumentIntelligence(),
            target: '_blank',
            iconProps: {
                iconName: 'FormRecognizerStudio'
            }
        },
        {
            key: LinksKind.ContentSafety,
            name: isContentSafetyLaunched
                ? localize(messages.ContentSafety)
                : localize(messages.ContentSafety) + localize(messages.ComingSoon),
            href: isContentSafetyLaunched ? studioLinks.ContentSafety() : '',
            target: '_blank',
            iconProps: {
                iconName: 'ContentSafetyStudio'
            }
        },
        {
            key: LinksKind.AzureAIStudio,
            name: localize(messages.AzureAIStudio),
            href: studioLinks.AzureAIStudio(),
            target: '_blank',
            iconProps: {
                iconName: 'OpenAiStudio'
            }
        },
        {
            key: LinksKind.AzureMachineLearning,
            name: localize(messages.AzureMachineLearning),
            href: studioLinks.AzureMachineLearning(),
            target: '_blank',
            iconProps: {
                iconName: 'MachineLearningStudio'
            }
        }
    ];

    const items = linksMenuItems.filter(item => props.items.includes(item.key as LinksKind));
    const headerTheme = getScopedTheme('LoggedInHeader');

    const rootStyles: IStyle = {
        marginTop: '4px',
        backgroundColor: headerTheme.avatarBackgroundColor,
        ul: {
            backgroundColor: headerTheme.avatarBackgroundColor
        },
        li: {
            padding: '2px',
            color: DefaultPalette.neutralPrimary,
            backgroundColor: headerTheme.avatarBackgroundColor
        },
        'a, button': {
            paddingRight: '20px',
            boxSizing: 'border-box',
            border: `1px solid transparent`,
            color: DefaultPalette.neutralPrimary,
            FontSizes: 14
        },
        'a:hover, button:hover': {
            textDecoration: 'underline',
            color: headerTheme.avatarForegroundColor,
            backgroundColor: headerTheme.avatarBackgroundColor,
            borderColor: `${headerTheme.avatarForegroundColor}`
        }
    };
    items.forEach((item, index) => {
        if (itemsWithAboveLine.includes(item.key as LinksKind)) {
            rootStyles[`li:nth-child(${index + 1})`] = {
                marginTop: '2px',
                paddingTop: '4px',
                borderTop: `1px solid ${DefaultPalette.neutralQuaternary}`
            };
        }
    });

    const menuProps: IContextualMenuProps = useConst({
        alignTargetEdge: false,
        shouldFocusOnMount: true,
        calloutProps: {
            styles: {
                root: rootStyles
            },
            preventDismissOnEvent: ev => {
                // to fix the issue when panel isblocking,linkmenu should be persist
                if (ev.type === 'focus') {
                    return true;
                }
                // this is default value
                return false;
            }
        } as ICalloutProps,
        items
    });

    return (
        <IconButton
            aria-label={localize(messages.LinkToOtherStudios)}
            menuProps={menuProps}
            iconProps={{ iconName: 'WaffleOffice365' }}
            style={{ paddingTop: 4, color: '#FFFFFF', backgroundColor: '#0078D4' }}
            onRenderMenuIcon={() => null}
        />
    );
};
