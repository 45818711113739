import { createOperationCall, createOperationCallAndPoll, OperationHandler } from 'src/core/services/api/createOperationCall';
import { fileService } from 'src/core/services/api/fileService';
import { httpService } from 'src/core/services/api/httpService';
import { OperationStatusBaseSwagger } from 'src/core/services/api/operationSwagger';
import { ServiceKey } from 'src/core/stores/AzureSessionStore';
import { ResourceListResults } from 'src/core/types/ResourceListTypes';
import { luisRequestOptionsBuilder } from 'src/core/utils/api/luisRequestOptionsBuilder';
import { urlBuilder } from 'src/core/utils/api/urlBuilder';
import { createService } from 'src/core/utils/createService';
import { LuisConstants } from 'src/core/utils/LuisConstants';
import {
    CustomSentimentCopyProjectSwagger,
    CustomSentimentExportedProjectSwagger,
    CustomSentimentProjectJobStatusSwagger,
    CustomSentimentProjectSwagger
} from 'src/customSentiment/core/services/projects/CustomSentimentProjectSwaggers';
import { CustomSentimentProjectKind } from 'src/customSentiment/core/stores/CustomSentimentProjectStore';

export const getSentimentProjectService = createService(({ context, getEnvironment }) => {
    const { apiUrl, isProduction } = getEnvironment({ apiName: 'customText' });

    const apiOptions = (serviceKey?: ServiceKey, options?: { isMergePatchType?: boolean }) =>
        luisRequestOptionsBuilder()
            .useDefaultOptions(serviceKey ?? context.serviceKey, isProduction)
            .useContentType(options?.isMergePatchType ? 'merge-patch+json' : 'json')
            .build();

    const getUrl = (projectName?: string, resource?: string) => {
        const builder = urlBuilder()
            .baseUrl(apiUrl)
            .project(projectName)
            .queryParam('api-version', LuisConstants.CustomText.AuthoringAprilApiVersion);

        if (resource) {
            builder.resource(resource);
        }

        return builder.build();
    };

    const createOrUpdateProject = async ({
        projectName,
        projectKind,
        multiLingual,
        description,
        language,
        storageInputContainerName
    }: {
        projectName: string;
        language?: string;
        description?: string;
        multiLingual?: boolean;
        storageInputContainerName?: string;
        projectKind?: CustomSentimentProjectKind;
    }): Promise<CustomSentimentProjectSwagger> => {
        const { data: createdProject } = await httpService.patch<CustomSentimentProjectSwagger>(
            getUrl(projectName),
            {
                projectName,
                projectKind,
                multiLingual,
                description,
                language,
                storageInputContainerName,
                multilingual: multiLingual
            },
            apiOptions(undefined, { isMergePatchType: true })
        );

        return createdProject;
    };

    const getProjects = async (nextLink?: string, serviceKey?: ServiceKey): Promise<ResourceListResults<CustomSentimentProjectSwagger>> => {
        const url = nextLink ?? getUrl();

        const { data: projectsListObj } = await httpService.get<ResourceListResults<CustomSentimentProjectSwagger>>(
            url,
            apiOptions(serviceKey)
        );

        // This filter is required because the same API returns abstractive summarization project kinds as well
        return {
            ...projectsListObj,
            value: projectsListObj.value.filter(p => p.projectKind === 'CustomTextSentiment')
        };
    };

    const getProject = async ({ projectName }: { projectName: string }): Promise<CustomSentimentProjectSwagger> => {
        const { data: project } = await httpService.get<CustomSentimentProjectSwagger>(getUrl(projectName), apiOptions());

        return project;
    };

    const deleteProject = async ({
        projectName,
        operationHandle
    }: {
        projectName: string;
        operationHandle: OperationHandler<CustomSentimentProjectJobStatusSwagger>;
    }): Promise<void> => {
        const { headers: deleteJobHeaders } = await httpService.delete<void>(getUrl(projectName), apiOptions());

        const jobUrl = (<Record<string, string>>deleteJobHeaders)[LuisConstants.OperationHeaders.LocationHeader];
        await createOperationCall<CustomSentimentProjectJobStatusSwagger>(jobUrl, apiOptions(), operationHandle);
    };

    const initiateExportProject = async ({
        projectName,
        showAutoTag = false,
        assetsToExport,
        trainedModelName
    }: {
        projectName: string;
        showAutoTag?: boolean;
        assetsToExport: ('all' | 'documents')[];
        trainedModelName?: string;
    }): Promise<string> => {
        const urlBuild = urlBuilder()
            .baseUrl(apiUrl)
            .project(projectName)
            .resource(':export')
            .queryParam('api-version', LuisConstants.CustomText.AuthoringAprilApiVersion)
            .queryParam('stringIndexType', 'Utf16CodeUnit');

        if (showAutoTag) {
            urlBuild.queryParam('showAutoTag', 'true');
        }

        if (trainedModelName) {
            urlBuild.queryParam('trainedModelLabel', trainedModelName);
        }

        const url = urlBuild.build();

        const payload = {
            assetsToExport: assetsToExport.some(a => a === 'all') ? ['*'] : assetsToExport
        };

        const options = luisRequestOptionsBuilder()
            .useDefaultOptions(context.serviceKey, isProduction)
            .useCustomHeader('format', 'JSON')
            .build();

        const { headers } = await httpService.post<void>(url, payload, options);

        return (<Record<string, string>>headers)[LuisConstants.OperationHeaders.CTLocationHeader];
    };

    const pollExportProject = async ({
        statusUrl,
        operationHandler
    }: {
        statusUrl: string;
        operationHandler: OperationHandler<CustomSentimentProjectJobStatusSwagger>;
    }): Promise<void> => {
        await createOperationCall(statusUrl, apiOptions(), operationHandler);
    };

    const getExportProjectResults = async ({ resultUrl }: { resultUrl: string }): Promise<CustomSentimentExportedProjectSwagger> => {
        const { data: exportedProject } = await httpService.get<CustomSentimentExportedProjectSwagger>(resultUrl, apiOptions());

        return exportedProject;
    };

    const initiateAndPollImportProject = async ({
        projectName,
        projectToImport,
        operationHandler,
        apiVersion = LuisConstants.CustomText.AuthoringAprilApiVersion
    }: {
        apiVersion?: string;
        projectName: string;
        projectToImport: CustomSentimentExportedProjectSwagger;
        operationHandler: OperationHandler<CustomSentimentProjectJobStatusSwagger>;
    }): Promise<void> => {
        const url = getUrl(projectName, ':import');

        const options = luisRequestOptionsBuilder()
            .useDefaultOptions(context.serviceKey, isProduction)
            .useCustomHeader('format', 'JSON')
            .build();

        const { headers } = await httpService.post<void>(url, { ...projectToImport, projectFileVersion: apiVersion }, options);

        const statusUrl = (<Record<string, string>>headers)[LuisConstants.OperationHeaders.CTLocationHeader];

        await createOperationCall(statusUrl, apiOptions(), operationHandler);
    };

    const exportProject = async ({ projectName, trainedModelName }: { projectName: string; trainedModelName?: string }) => {
        let urlBuild = urlBuilder()
            .baseUrl(apiUrl)
            .project(projectName)
            .resource(':export')
            .queryParam('api-version', LuisConstants.CustomText.AuthoringAprilApiVersion)
            .queryParam('stringIndexType', 'Utf16CodeUnit');

        if (trainedModelName) {
            urlBuild = urlBuild.queryParam('trainedModelLabel', trainedModelName);
        }
        const url = urlBuild.build();

        const options = luisRequestOptionsBuilder()
            .useDefaultOptions(context.serviceKey, isProduction)
            .useCustomHeader('format', 'JSON')
            .build();

        const { data } = await createOperationCallAndPoll<OperationStatusBaseSwagger & { resultUrl: string }>(
            httpService.post(url, { assets: ['*'] }, options),
            apiOptions(),
            undefined,
            response => (<Record<string, string>>response.headers)[LuisConstants.OperationHeaders.LocationHeader]
        );

        const { data: projectData } = await httpService.get<CustomSentimentProjectSwagger>(data.resultUrl, apiOptions());

        const fileName = trainedModelName ? `${projectName}(${trainedModelName}).json` : `${projectName}.json`;
        fileService.saveToDisk(JSON.stringify(projectData, null, 4), fileName);
    };

    const authorizeCopyProject = async ({
        serviceKey,
        resourceId,
        projectName,
        projectKind,
        allowOverwrite,
        targetResourceEndpoint,
        storageInputContainerName
    }: {
        serviceKey: string;
        resourceId: string;
        projectName: string;
        allowOverwrite: boolean;
        targetResourceEndpoint: string;
        storageInputContainerName: string;
        projectKind: CustomSentimentProjectKind;
    }): Promise<CustomSentimentCopyProjectSwagger> => {
        const url = isProduction
            ? urlBuilder()
                  .baseUrl(`${targetResourceEndpoint}/language/authoring/analyze-text`)
                  .project(projectName)
                  .resource(':authorize-copy')
                  .queryParam('api-version', LuisConstants.CustomText.AuthoringAprilApiVersion)
                  .build()
            : getUrl(projectName, ':authorize-copy');

        const resourceKey = isProduction ? serviceKey : resourceId;
        const modifiedApiOptions = luisRequestOptionsBuilder()
            .useAuthoringKey(resourceKey, isProduction, resourceId)
            .build();

        const response = await httpService.post<CustomSentimentCopyProjectSwagger>(
            url,
            { projectKind, allowOverwrite, storageInputContainerName },
            modifiedApiOptions
        );

        return response.data;
    };

    const copyProject = async ({
        operationHandle,
        copyProjectSwagger
    }: {
        copyProjectSwagger: CustomSentimentCopyProjectSwagger;
        operationHandle: OperationHandler<CustomSentimentProjectJobStatusSwagger>;
    }): Promise<void> => {
        const url = getUrl(copyProjectSwagger.targetProjectName, ':copy');

        const { headers: copyJobHeaders } = await httpService.post<void>(url, copyProjectSwagger, apiOptions());

        const jobUrl = (<Record<string, string>>copyJobHeaders)[LuisConstants.OperationHeaders.LocationHeader];

        await createOperationCall<CustomSentimentProjectJobStatusSwagger>(jobUrl, apiOptions(), operationHandle);
    };

    return {
        createOrUpdateProject,
        getProjects,
        getProject,
        deleteProject,
        authorizeCopyProject,
        copyProject,
        initiateExportProject,
        pollExportProject,
        getExportProjectResults,
        initiateAndPollImportProject,
        exportProject
    };
});
