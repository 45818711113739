import { transaction } from 'mobx';
import { OperationHandler } from 'src/core/services/api/createOperationCall';
import { fileService } from 'src/core/services/api/fileService';
import { getAzureBlobService } from 'src/core/services/azure/azureBlobService';
import { getAzureService } from 'src/core/services/azure/azureService';
import { AzureBlobSwagger } from 'src/core/services/azure/azureSwaggers';
import { getCustomSkillGlobalsService } from 'src/core/services/customSkillGlobalService';
import { AzureCsAccountStore } from 'src/core/stores/AzureCsAccountStore';
import { ServiceKey } from 'src/core/stores/AzureSessionStore';
import { CustomSkillGlobalsStore } from 'src/core/stores/CustomSkillGlobalsStore';
import { AppStore } from 'src/core/stores/app/AppStore';
import { HandlerGroupFactoryDeps, HandlerParams } from 'src/core/types/handler';
import { LuisConstants } from 'src/core/utils/LuisConstants';
import { OperationDelayStrategies } from 'src/core/utils/api/operationDelayStrategies';
import { batchPromises, getNextAbleResults } from 'src/core/utils/batchPromises';
import { getStorage } from 'src/core/utils/getStorage';
import {
    CustomSentimentCopyProjectSwagger,
    CustomSentimentExportedProjectSwagger,
    CustomSentimentProjectJobStatusSwagger
} from 'src/customSentiment/core/services/projects/CustomSentimentProjectSwaggers';
import { getSentimentProjectService } from 'src/customSentiment/core/services/projects/customSentimentProjectService';
import {
    CustomSentimentDocumentStore,
    emptyCustomSentimentDocumentMetadata
} from 'src/customSentiment/core/stores/CustomSentimentDocumentStore';
import { CustomSentimentProjectKind, CustomSentimentProjectStore } from 'src/customSentiment/core/stores/CustomSentimentProjectStore';
import {
    documentMetadataToDocumentStore,
    exportedProjectSwaggerToDocumentAndTagStores,
    projectStoreToExportedProjectSwagger,
    projectSwaggerToProjectStore
} from 'src/customSentiment/modules/handlers/projects/customSentimentProjectMappers';
import { LabelsFileNamesStore } from 'src/customText/core/stores/LabelsFileNamesStore';
import { ResourceStoreHouse } from 'src/luis/types/stores/core/ResourceStoreHouse';
import { TrainingConfigVersionSwagger } from 'src/modules/modelTraining/types/trainingSwaggers';
import { TrainingConfigVersion } from 'src/modules/modelTraining/types/trainingTypes';

export type CustomSentimentProjectHandlerGroup = ReturnType<typeof createCustomSentimentProjectHandlerGroup>;

export const loadSentimentProjects = async (serviceKey?: ServiceKey): Promise<CustomSentimentProjectStore[]> => {
    const projectSwaggers = await getNextAbleResults(getSentimentProjectService().getProjects, 'value', [serviceKey]);

    return projectSwaggers.map(s => projectSwaggerToProjectStore(s, []));
};

/**
 * Represents a handler group for custom Sentiment project operations.
 *
 * @param dependencies The handler group dependencies.
 */
export const createCustomSentimentProjectHandlerGroup = (dependencies: HandlerGroupFactoryDeps) => {
    const { rootStore, lifetime } = dependencies;
    const azureBlobService = getAzureBlobService();

    const documentsInCurrentProjectStore = new Map<string, boolean>();

    let nextBatchMarker = '';

    const getExportPollOperationHandler = (): OperationHandler<CustomSentimentProjectJobStatusSwagger> => ({
        retryStrategy: res => !lifetime.isDisposed && res.data.status !== 'succeeded' && res.data.status !== 'failed',
        timeToDelay: OperationDelayStrategies.exponentialDelayStrategy,
        handleCall: async data => {
            if (!lifetime.isDisposed && data.status === 'failed') {
                throw new Error(data.errors[0]?.message);
            }

            if (lifetime.isDisposed || data.status !== 'succeeded') {
                return;
            }

            const { currentSentimentProjectStore } = rootStore;

            const results = await getSentimentProjectService().getExportProjectResults({ resultUrl: data.resultUrl });
            try {
                const { documents } = exportedProjectSwaggerToDocumentAndTagStores(results, currentSentimentProjectStore.documents);
                currentSentimentProjectStore.setProjectDocuments(documents);
                if (currentSentimentProjectStore.currentDocumentStore) {
                    const updatedCurrentDocumentStore = documents.find(
                        d => d.name === currentSentimentProjectStore.currentDocumentStore.name
                    );
                    if (updatedCurrentDocumentStore) {
                        currentSentimentProjectStore.setCurrentDocumentStore(documentMetadataToDocumentStore(updatedCurrentDocumentStore));
                    }
                }
            } catch (e) {
                throw new Error(
                    'Error loading your project. Please make sure that your tags file in the container was not manually edited and try importing your project again.'
                );
            }
        }
    });

    const getImportPollOperationHandler = (): OperationHandler<CustomSentimentProjectJobStatusSwagger> => ({
        timeToDelay: OperationDelayStrategies.exponentialDelayStrategy,
        retryStrategy: res => !lifetime.isDisposed && res.data.status !== 'succeeded' && res.data.status !== 'failed',
        handleCall: data => {
            if (data.status === 'failed') {
                const error = data.errors?.[0];

                throw new Error(`${error.message} ${error.innererror ? `: ${error.innererror.message}` : ''}`);
            }
        }
    });

    const getSentimentProjectTrainingConfigVersions = async (projectKind: CustomSentimentProjectKind) => {
        const { sentimentGlobalsStoreHouse } = rootStore;

        let globalsStore = sentimentGlobalsStoreHouse.items.find(i => i.id === projectKind);
        if (!globalsStore) {
            globalsStore = new CustomSkillGlobalsStore(projectKind);
            sentimentGlobalsStoreHouse.add(globalsStore);
        }

        if (!globalsStore.trainingConfigVersions?.length) {
            const results = await getNextAbleResults<'value', TrainingConfigVersionSwagger>(
                (nextLink: string) => getCustomSkillGlobalsService().getTrainingConfigVersions(projectKind, nextLink),
                'value'
            );

            globalsStore.setTrainingConfigVersions(
                results.map(
                    r =>
                        <TrainingConfigVersion>{
                            modelExpirationDate: r?.modelExpirationDate ? new Date(r.modelExpirationDate) : undefined,
                            trainingConfigVersion: r.trainingConfigVersion,
                            isLatest: r.isLatest
                        }
                )
            );
        }
    };

    const getSentimentCultures = async ({}: HandlerParams<object>) => {
        const { sentimentCultureStorehouse } = rootStore;

        if (sentimentCultureStorehouse.tracker.isRunning || sentimentCultureStorehouse.tracker.isCompleted) {
            return;
        }

        try {
            sentimentCultureStorehouse.tracker.setState('running');
            const culturesSwaggers = await getNextAbleResults(
                () => getCustomSkillGlobalsService().getProjectCultures('CustomTextSentiment'),
                'value'
            );
            const cultureStores = culturesSwaggers.map(swagger => ({
                name: swagger.languageName,
                id: swagger.languageCode,
                code: swagger.languageCode,
                isoCode: swagger.languageCode
            }));
            sentimentCultureStorehouse.setItems(cultureStores);
            sentimentCultureStorehouse.tracker.setState('completed');
        } catch (e) {
            sentimentCultureStorehouse.tracker.setState('failed', <Error>e);

            throw e;
        }
    };

    const getSentimentProjects = async (_?: HandlerParams) => {
        const { sentimentProjectStoreHouse } = rootStore;

        try {
            sentimentProjectStoreHouse.tracker.setState('running');

            const [projectStores] = await Promise.all([loadSentimentProjects(), getSentimentCultures({})]);

            transaction(() => {
                sentimentProjectStoreHouse.setItems(projectStores);
                sentimentProjectStoreHouse.tracker.setState('completed');
            });
        } catch (err) {
            sentimentProjectStoreHouse.setItems([]);
            sentimentProjectStoreHouse.tracker.setState('failed', <Error>err);

            throw err;
        }
    };

    const fetchSentimentProjectAvailableLabelsFileNames = async ({
        localStore,
        containerName,
        storageAccountName,
        prefix
    }: HandlerParams<{
        containerName: string;
        storageAccountName: string;
        localStore: LabelsFileNamesStore;
        prefix?: string;
    }>) => {
        const { storageToken } = rootStore.azureStore.session.userTokens;
        localStore.tracker.setState('running');
        try {
            const allBlobs = await azureBlobService.getAzureBlobsByContainerName({
                storageToken,
                containerName,
                storageAccountName,
                prefix
            });

            const labelsFilesNames = allBlobs.filter(documentSwagger => documentSwagger.name.includes('.json')).map(f => ({ id: f.name }));

            const canDisplayLabelFiles = allBlobs.length < LuisConstants.CustomText.MaximumLabelsFilesDisplayed || Boolean(prefix);
            localStore.setCanDisplayLabelFiles(canDisplayLabelFiles);

            localStore.setItems(canDisplayLabelFiles ? labelsFilesNames : []);
            localStore.tracker.setState('completed');
        } catch (err) {
            localStore.tracker.setState('failed', <Error>err);
        }
    };

    const updateSentimentProject = async ({
        name,
        multiLingual,
        description
    }: HandlerParams<Pick<CustomSentimentProjectStore, 'name' | 'description' | 'multiLingual'>>) => {
        const projectToUpdate = rootStore.sentimentProjectStoreHouse.findById(name);

        await getSentimentProjectService().createOrUpdateProject({
            projectName: name,
            description,
            multiLingual,
            language: projectToUpdate.culture,
            projectKind: projectToUpdate.projectKind,
            storageInputContainerName: projectToUpdate.containerName
        });

        projectToUpdate.setMultiLingual(multiLingual);
        projectToUpdate.setDescription(description);
    };

    const deleteSentimentProject = async ({
        projectNames,
        appsLocalStoreHouse,
        generateMultiFailureMessage
    }: HandlerParams<{
        projectNames: string[];
        appsLocalStoreHouse?: ResourceStoreHouse<AppStore>;
        generateMultiFailureMessage: (failedCount: number) => string;
    }>) => {
        const { sentimentProjectStoreHouse: sentimentProjectsStoreHouse } = rootStore;
        const storeHouse = appsLocalStoreHouse || sentimentProjectsStoreHouse;
        storeHouse.tracker.setState('running');

        const { results, failedCount } = await batchPromises(
            projectNames.map(projectName =>
                getSentimentProjectService().deleteProject({
                    projectName,
                    operationHandle: {
                        timeToDelay: OperationDelayStrategies.exponentialDelayStrategy,
                        retryStrategy: res => res.data.status !== 'succeeded' && res.data.status !== 'failed',
                        handleCall: data => {
                            if (data.status === 'failed') {
                                throw new Error(data.errors?.[0]?.message);
                            }
                        }
                    }
                })
            )
        );

        const succeededIds = results.filter(res => res.status === 'success').map((_, index) => projectNames[index]);

        if (failedCount > 0) {
            throw new Error(generateMultiFailureMessage(failedCount));
        }

        transaction(() => {
            storeHouse.batchRemoveByIds(succeededIds);
            storeHouse.tracker.setState('completed');
        });
    };

    const fetchSentimentProjectDocuments = async ({
        containerName,
        storageAccountName,
        storageToken,
        prefix,
        projectKind
    }: HandlerParams<{
        containerName: string;
        storageAccountName: string;
        storageToken: string;
        prefix?: string;
        projectKind: CustomSentimentProjectKind;
    }>): Promise<AzureBlobSwagger[]> => {
        const allBlobs = await azureBlobService.getAzureBlobsByContainerName({
            containerName,
            storageAccountName,
            storageToken,
            prefix,
            allPortions: true
        });

        return allBlobs.filter(documentSwagger =>
            projectKind === 'CustomTextSentiment'
                ? documentSwagger.name.endsWith('.json') && !documentSwagger.name.endsWith('_labels.json')
                : documentSwagger.name.endsWith('.txt')
        );
    };

    const fetchSentimentProjectDocumentsInBatches = async ({
        containerName,
        storageAccountName,
        storageToken,
        containerMarker
    }: HandlerParams<{
        containerName: string;
        storageAccountName: string;
        storageToken: string;
        containerMarker?: string;
    }>): Promise<{
        blobs: AzureBlobSwagger[];
        nextMarker: string;
    }> => {
        const { blobs, nextMarker } = await azureBlobService.getAzureBlobsByContainerNameInBatches({
            containerName,
            storageAccountName,
            storageToken,
            marker: containerMarker
        });

        return {
            blobs: blobs.filter(documentSwagger => documentSwagger.name.includes('txt')),
            nextMarker
        };
    };

    const createSentimentProject = async ({
        projectName,
        projectKind,
        projectDescription,
        defaultLanguage,
        containerName,
        multiLingual
    }: HandlerParams<{
        projectName: string;
        projectDescription: string;
        defaultLanguage: string;
        containerName: string;
        multiLingual: boolean;
        projectKind: CustomSentimentProjectKind;
    }>) => {
        await getSentimentProjectService().createOrUpdateProject({
            projectName,
            projectKind,
            multiLingual,
            language: defaultLanguage,
            description: projectDescription,
            storageInputContainerName: containerName
        });
    };

    const openSentimentProjectWithBatchLoading = async ({
        projectName
    }: HandlerParams<{ projectName: string; projectKind?: CustomSentimentProjectKind }>) => {
        const [projectSwagger] = await Promise.all([getSentimentProjectService().getProject({ projectName }), getSentimentCultures({})]);

        await getSentimentProjectTrainingConfigVersions(projectSwagger.projectKind);

        const { ownedStorageAccountInfo } = rootStore.azureStore.session.csAccountStore;
        const { storageToken } = rootStore.azureStore.session.userTokens;

        const { blobs: documentSwaggers, nextMarker } = await fetchSentimentProjectDocumentsInBatches({
            containerName: projectSwagger.storageInputContainerName,
            storageAccountName: ownedStorageAccountInfo.name,
            storageToken
        });

        const documentsMetadata = documentSwaggers.map(d => ({
            ...emptyCustomSentimentDocumentMetadata,
            name: d.name,
            language: projectSwagger.language,
            contentSize: +d.size
        }));

        const exportStatusUrl = await getSentimentProjectService().initiateExportProject({
            projectName,
            assetsToExport: ['all']
        });
        const projectStore = projectSwaggerToProjectStore(projectSwagger, documentsMetadata);

        documentsMetadata.forEach(d => documentsInCurrentProjectStore.set(d.name, true));
        nextBatchMarker = nextMarker;

        transaction(() => {
            projectStore.setCsAccountPath(rootStore.azureStore.session.csAccountStore?.path);
            rootStore.sentimentProjectStoreHouse.setItems([projectStore]);
            rootStore.setCurrentSentimentProjectStore(projectStore);
            rootStore.currentSentimentProjectStore.setNextBatchExists(Boolean(nextMarker));
        });

        await getSentimentProjectService().pollExportProject({
            statusUrl: exportStatusUrl,
            operationHandler: getExportPollOperationHandler()
        });
    };

    const getSentimentNextBatchOfDocuments = async (_?: HandlerParams) => {
        const { currentSentimentProjectStore } = rootStore;
        const { ownedStorageAccountInfo } = rootStore.azureStore.session.csAccountStore;
        const { storageToken } = rootStore.azureStore.session.userTokens;

        const { blobs: nextBatch, nextMarker } = await fetchSentimentProjectDocumentsInBatches({
            storageToken,
            containerMarker: nextBatchMarker,
            storageAccountName: ownedStorageAccountInfo.name,
            containerName: currentSentimentProjectStore.containerName
        });

        const documentStores = nextBatch
            .map(
                d =>
                    new CustomSentimentDocumentStore({
                        name: d.name,
                        language: currentSentimentProjectStore.culture,
                        contentSize: +d.size
                    })
            )
            .filter(d => !documentsInCurrentProjectStore.has(d.name));

        documentStores.forEach(d => documentsInCurrentProjectStore.set(d.name, true));
        nextBatchMarker = nextMarker;

        transaction(() => {
            currentSentimentProjectStore.setProjectDocuments(currentSentimentProjectStore.documents.concat(documentStores));
            currentSentimentProjectStore.setNextBatchExists(Boolean(nextMarker));
        });
    };

    const getSentimentSpecificBatchWithPrefix = async ({ prefix }: HandlerParams<{ prefix: string }>) => {
        const { currentSentimentProjectStore } = rootStore;
        const { ownedStorageAccountInfo } = rootStore.azureStore.session.csAccountStore;
        const { storageToken } = rootStore.azureStore.session.userTokens;

        const blobs = await fetchSentimentProjectDocuments({
            prefix,
            storageToken,
            projectKind: currentSentimentProjectStore.projectKind,
            storageAccountName: ownedStorageAccountInfo.name,
            containerName: currentSentimentProjectStore.containerName
        });

        const documentStores = blobs
            .map(d => new CustomSentimentDocumentStore({ name: d.name, language: currentSentimentProjectStore.culture }))
            .filter(d => !documentsInCurrentProjectStore.has(d.name));

        documentStores.forEach(d => documentsInCurrentProjectStore.set(d.name, true));

        transaction(() => {
            currentSentimentProjectStore.setProjectDocuments(currentSentimentProjectStore.documents.concat(documentStores));
        });
    };

    const updateSentimentProjectAssets = async ({}: HandlerParams<object>) => {
        const { currentSentimentProjectStore } = rootStore;
        const projectToImport = projectStoreToExportedProjectSwagger(currentSentimentProjectStore);
        await getSentimentProjectService().initiateAndPollImportProject({
            projectName: currentSentimentProjectStore.name,
            projectToImport,
            operationHandler: getImportPollOperationHandler()
        });

        currentSentimentProjectStore.setIsDirty(false);
    };

    const importSentimentLabels = async ({
        projectKind,
        projectName,
        multiLingual,
        containerName,
        labelsFileName,
        defaultLanguage,
        projectDescription
    }: HandlerParams<{
        projectName: string;
        containerName: string;
        multiLingual: boolean;
        labelsFileName: string;
        defaultLanguage: string;
        projectDescription: string;
        projectKind: CustomSentimentProjectKind;
    }>) => {
        // Read labels file.
        const { session } = rootStore.azureStore;
        const { storageToken } = session.userTokens;
        const { ownedStorageAccountInfo } = rootStore.azureStore.session.csAccountStore;

        let assets: CustomSentimentExportedProjectSwagger['assets'] = null;
        const fileContent = await azureBlobService.getAzureBlobContent<CustomSentimentExportedProjectSwagger>({
            storageToken,
            containerName,
            blobName: labelsFileName,
            storageAccountName: ownedStorageAccountInfo.name
        });

        assets = fileContent.assets;

        // Import custom Sentiment application.
        await getSentimentProjectService().initiateAndPollImportProject({
            projectName,
            projectToImport: {
                assets,
                metadata: {
                    projectKind,
                    multiLingual,
                    projectName,
                    language: defaultLanguage,
                    description: projectDescription,
                    storageInputContainerName: containerName
                },
                stringIndexType: 'Utf16CodeUnit'
            } as CustomSentimentExportedProjectSwagger,
            operationHandler: getImportPollOperationHandler(),
            apiVersion: LuisConstants.CustomText.AuthoringAprilApiVersion
        });
    };

    const closeSentimentProject = async () => {
        rootStore.setCurrentSentimentProjectStore(null);

        // Cleaning the session storage from the cached docs for free some space up
        const sessionStorage = getStorage('sessionStorage');
        sessionStorage.clear();
    };

    const exportSentimentProject = async ({
        projectName,
        trainedModelName
    }: HandlerParams<{ projectName: string; trainedModelName?: string }>) => {
        await getSentimentProjectService().exportProject({ projectName, trainedModelName });
    };

    const importSentimentProject = async ({ file, newProjectName, tracker }: HandlerParams<{ file: File; newProjectName?: string }>) => {
        const fileStringifiedContent = await fileService.readFromDisk(file);
        let fileJsonContent: CustomSentimentExportedProjectSwagger;

        const invalidJsonError = new Error();
        invalidJsonError.name = 'Invalid format';
        invalidJsonError.message = 'The file provided is of invalid JSON format.';

        try {
            fileJsonContent = JSON.parse(fileStringifiedContent);
        } catch {
            throw invalidJsonError;
        }

        if (
            !fileJsonContent.assets ||
            !fileJsonContent?.metadata?.language ||
            !fileJsonContent?.metadata?.projectName ||
            !fileJsonContent?.metadata?.storageInputContainerName ||
            !fileJsonContent?.metadata?.projectKind ||
            fileJsonContent?.metadata?.projectKind !== 'CustomTextSentiment'
        ) {
            throw invalidJsonError;
        }

        const projectName = newProjectName || fileJsonContent.metadata.projectName;

        await getSentimentProjectService().initiateAndPollImportProject({
            projectName,
            projectToImport: { ...fileJsonContent, metadata: { ...fileJsonContent.metadata, projectName } },
            operationHandler: getImportPollOperationHandler(),
            apiVersion: LuisConstants.CustomText.AuthoringAprilApiVersion
        });

        if (tracker) {
            tracker.setContext(projectName);
        }
    };

    const copySentimentProject = async ({
        targetResource,
        projectName,
        projectKind,
        allowOverwrite,
        storageInputContainerName
    }: HandlerParams<{
        projectName: string;
        allowOverwrite: boolean;
        storageInputContainerName: string;
        targetResource: AzureCsAccountStore;
        projectKind: CustomSentimentProjectKind;
    }>) => {
        let keys = targetResource.keyStrings;

        if (!keys?.key1) {
            const tenantToken = rootStore.azureStore.session.userTokens.armToken;
            keys = await getAzureService().getAzureCsAccountKeyStringsByPath({ tenantToken, path: targetResource.path });
        }
        const authorizeCopyResponse: CustomSentimentCopyProjectSwagger = await getSentimentProjectService().authorizeCopyProject({
            serviceKey: keys.key1,
            resourceId: targetResource.id,
            targetResourceEndpoint: targetResource.endpoint,
            projectKind,
            projectName,
            allowOverwrite,
            storageInputContainerName
        });

        await getSentimentProjectService().copyProject({
            copyProjectSwagger: authorizeCopyResponse,
            operationHandle: {
                timeToDelay: OperationDelayStrategies.exponentialDelayStrategy,
                retryStrategy: res => res.data.status !== 'succeeded' && res.data.status !== 'failed',
                handleCall: data => {
                    if (data.status === 'failed') {
                        throw new Error(data.errors?.[0]?.message);
                    }
                }
            }
        });
    };

    return {
        getSentimentProjects,
        getSentimentCultures,
        copySentimentProject,
        importSentimentLabels,
        closeSentimentProject,
        deleteSentimentProject,
        importSentimentProject,
        updateSentimentProject,
        createSentimentProject,
        exportSentimentProject,
        updateSentimentProjectAssets,
        getSentimentNextBatchOfDocuments,
        getSentimentSpecificBatchWithPrefix,
        openSentimentProjectWithBatchLoading,
        fetchSentimentProjectAvailableLabelsFileNames
    };
};
